<div class="page-main-header" [class.open]="open">
    <div class="main-header-right row">
        <div class="main-header-left d-lg-none col">
            <div class="logo-wrapper" style="background-color: #2B2D38;">
                <a [routerLink]="'/dashboard'" class="w-100">
                    <img class="blur-up lazyloaded w-100" ngSrc="assets/images/Logo-agencia-abascal.png" width="90"
                        height="50" priority="true" alt="">
                </a>
            </div>
        </div>
        <div class="mobile-sidebar col text-left">
            <div class="media-body text-left switch-sm d-flex align-items-center">
                <label class="switch m-0">
                    <a>
                        <i class="pi pi-bars" style="font-size: 2rem" (click)="collapseSidebar()"></i>
                    </a>
                </label>

                <!-- <a [routerLink]="['sale-point']" class="ml-3 btn btn-info" style="text-transform: capitalize;">Nueva
                    venta</a> -->

            </div>
        </div>
        <div class="nav-right col">
            <ul class="nav-menus mb-0" [ngClass]="{'open' : openNav}">
                <li class="onhover-dropdown">
                    <div class="media align-items-center">
                        <span class="name pdd-right-5">{{user?.displayName}}</span>
                        <i class="ti-angle-down font-size-10"></i>

                        <div class="dotted-animation"><span class="animate-circle"></span><span
                                class="main-circle"></span></div>
                    </div>
                    <!-- <ul class="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                        <li>
                            <a [routerLink]="['/pages/profile/', user?.id]" title="Editar usuario">
                                Editar perfil
                            </a>
                        </li>
                        <li >
                            <a>
                                Cerrar sesión
                            </a>
                        </li>
                    </ul> -->
                </li>
            </ul>
            <div class="d-lg-none mobile-toggle pull-right" (click)="openMobileNav()">
            </div>
        </div>
    </div>
</div>