import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AuthenticationService } from 'src/app/shared/service/api-services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { catchError, map, Subscription, switchMap, throwError } from 'rxjs';

@Component({
  selector: 'app-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: ['./activate-account.component.scss'],
  providers: [MessageService],
})
export class ActivateAccountComponent implements OnInit {
  public authForm: FormGroup;
  public submitted: boolean = false;
  public isLogin: boolean = true;
  public error: boolean = false;

  constructor(
    private _fb: FormBuilder,
    private messageService: MessageService,
    private authService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.authForm = this._fb.group({
      password: [
        '',
        [
          Validators.required,
        ],
      ],
    });
  }

  public signActivateAccount(): void {
    this.activatedRoute.paramMap
      .pipe(
        map((params) => params.get('token')),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        switchMap((token: any) =>
          this.authService.activateEmail(
            token,
            this.authControls['password'].value
          )
        ),
        catchError((error) => {
          return throwError(error);
        })
      )
      .subscribe(
        (resolve) => {
          localStorage.setItem('currentUser', JSON.stringify(resolve));
          this.showMessage(
            'bc',
            'success',
            'Activación completada',
            'La cuenta se activo correctamente',
            'pi-check-circle'
          );
          setTimeout(() => {
            this.router.navigate(['/auth/login']);
          }, 5000);

          this.submitted = false;
          this.error = false;

        },
        (error: any) => {
          this.showMessage(
            'bc',
            'error',
            'Error',
            error.error.Message,
            'pi-info-circle'
          );
          this.submitted = false;
          this.error = false;
        }
      );

    this.submitted = true;
    if (this.authForm.invalid) {
      this.showMessage(
        'bc',
        'warn',
        'Advertencia',
        'Debes llenar todos los campos',
        'pi-info-circle'
      );
      this.error = true;
      this.submitted = false;
      return;
    }
  }

  buildUser() {
    return {
      email: this.authControls['email'].value,
    };
  }

  showMessage(
    key: string,
    type: string,
    title: string,
    content: string,
    icon: string
  ) {
    this.messageService.add({
      key: key,
      severity: type,
      summary: title,
      detail: content,
      icon: icon,
    });
  }

  public get authControls() {
    return this.authForm.controls;
  }

  ngOnInit(): void { }
}
